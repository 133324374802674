import axios from 'axios'

const api = "mercado-pago"

const Endpoints = {

    getCuentas(params){
        return axios.get(`${api}/cuentas`,{params})
    },
    getSelectCuentas(){
        return axios.get(`${api}/cuentas/select`)
    },
    getPagos(idCuenta, params){
        return axios.get(`${api}/${idCuenta}/pagos`, { params })
    },
    postCuenta(payload){
        return axios.post(`${api}/cuentas`, payload)
    },
    editEstadoCuentaCedis(idCuenta, payload){
        return axios.put(`${api}/cuentas/${idCuenta}/cedis/editar-estado`, payload)
    },
    editEstadoCuenta(idCuenta, payload){
        return axios.put(`${api}/cuentas/${idCuenta}/editar-estado`, payload)
    },
    editCuenta(idCuenta, payload){
        return axios.put(`${api}/cuentas/${idCuenta}/editar`,payload)
    },
    deleteCuenta(idCuenta){
        return axios.delete(`${api}/cuentas/${idCuenta}/eliminar`)
    }

}

export default Endpoints